// main colors
$primary-blue: #0185F3;
$primary-white: #FFFFFF;
$error: #F24455;
$text-color: #2F353C;

// input
$input-bg-color: #FFF8FA;
$input-border-color: #D0DFE8;
$input-border-color-error: $error;
$input-text-color: $text-color;
$input-text-color-error: #F24455;

// box component
$box-bg: #FFFBDA;
$box-border-color: #F6EFAF;

// auth
$authSection-bg: #FCFCFC;
$auth-text-color: #2F353B;

// study sidebar
$menu-item-active: #D3F2FF;
$menu-item0complete: #CAFFE2;

// study progress bar
$bar-bg: #EEEEEE;
$bar-bg-value: #15C73C;
$border-radius: 4px;

// onboarding flow
$onboard-wrapp-bg: #3F5D74;
$onboard-wrapp-shadow: 0px 2px 12px rgba(61, 83, 116, 0.06);
$onboard-bg: #FCFCFC;
$onboard-radius: 16px;

// leadership
$leadership-wrapp-radius: 16px;
$leadship-list-color: #002846;
$leadership-user-bg: #EAF9FE;
$leadership-user-radius: 16px;
$leadership-score-high: #D0FFE5;
$leadership-score-low: #FFE6ED;
$leadership-notify-bg: #15C73C;
$leadership-notify-drop: #F24455;

