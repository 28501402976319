.assessmentProgress {
  position: relative;
  display: block;
  width: 100%;
  height: 8px;
  margin-right: 3px;
  border-radius: 4px;
  //overflow: hidden;
  &::-webkit-progress-bar {
    background: #EEEEEE;
    border-radius: 4px;
  }
  &::-webkit-progress-value {
    background: #15C73C;
    border-radius: 4px 0 0 4px;
  }
  &[value="100"]::-webkit-progress-value {
    border-radius: 4px;
  }
}
.vocabularyWordList {
  text-align: left;
  h1 {
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #2F353C;
    margin-bottom: 32px;
  }
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2F353C;
    margin-bottom: 24px;
  }
}
.wordRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 68px;
  padding: 8px 20px;
  margin-bottom: 16px;
  background: #FFFFFF;
  border: 1px solid #D0DFE8;
  border-radius: 16px;
  position: relative;
  p {
    font-size: 15px;
    line-height: 22px;
    color: #2F353C;
    strong {
      font-weight: 600;
    }
  }
}
.vocabularyQuiz {
  text-align: left;
  [class*="testKeyContent"] {
    margin-bottom: 16px;
  }
  [class*="wordRow"] {
    margin-bottom: 0;
  }
}