@import "src/assets/css/mixins";
.notificationWrapper {
  width: 100%;
  max-width: 760px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(61, 83, 116, 0.06);
  border-radius: 16px;
  padding:32px;
  @include tablet {
    max-width: 100%;
  }
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #2F353B;
    margin-bottom: 32px;
  }
}
.notificationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 31px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #323232;
  }
  & + .notificationItem {
    margin-top: 16px;
  }
}