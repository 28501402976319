@import "src/assets/css/mixins";
.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #002846;
  z-index: 4;
  @include mobile {
    position: fixed;
    top: 0;
    & + div {
      margin-top: 64px;
    }
  }
  & > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    height: 64px;
    padding: 0 108px;
    @include tablet {
      padding: 0 24px;
    }
    @include mobile {
      justify-content: flex-start;
      padding: 0 16px;
    }
  }
}
.headerProfileItems {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 auto;
  margin: 0 16px 0 50px;
  @media screen and (max-width: 1130px) and (min-width: 1024px) {
    margin: 0 16px;
  }
  & > a {
    display: flex;
    align-items: center;
    height: 24px;
    color: #FFFFFF;
    text-decoration: none;
    & + a {
      margin-left: 32px;
      @media screen and (max-width: 1130px) and (min-width: 1024px) {
        margin-left: 16px;
      }
    }
    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
      text-transform: capitalize;
    }
  }
  .activePage{
    color: #0185F3;
  }
}
.headerProfileMenu {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-left: 32px;

  @media screen and (max-width: 1130px) and (min-width: 1024px) {
    margin-left: 16px;
  }
}
.headerProfileInfo {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 150px;
  height: 40px;
  padding: 0 24px 0 0;
  margin: 0 24px 0 0;
  border-right: 1px solid rgba(249, 249, 250, 0.5);
  &[class*="isActive"] {
    [class*="tabletProfileMenu"] {
      display: block;
    }
  }
  @media screen and (max-width: 1130px) and (min-width: 1024px) {
    padding: 0 16px 0 0;
    margin: 0 16px 0 0;
  }
  @include tablet {
    position: relative;
  }
  & > div {
    width: 100%;
    max-width: 40px;
    height: 40px;
    margin-right: 8px;
    flex: 1 0 40px;
    cursor: pointer;
  }
  & > p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }
}
.headerProfileLogout {
  width: 24px;
  height: 24px;
  margin-left: auto;
  & > svg {
    width: 24px;
    height: 24px;
  }
}
.dropdown {
  display: none;
}

.mobileNavigation {
  //display: none;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #002846;
  z-index: 9999;
  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 54px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  & > section {
    padding: 24px 16px;
  }
}
.navigationMain {
  padding: 24px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  [class*="headerProfileItems"] {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    & > a + a {
      margin: 32px 0 0 0!important;
    }
  }
}
.navigationProfile {
  padding: 24px 16px;
  [class*="asideMenuItem"] {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 24px;
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0!important;
    & > svg {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0!important;
    }
    & > svg path,
    & > svg rect,
    & > svg circle {
      stroke: #FFFFFF!important;
    }
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
    }
    & + [class*="asideMenuItem"] {
      margin-top: 32px!important;
    }
  }
}