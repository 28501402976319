.notifyHint {
  width: 100%;
  background: #FFFBDA;
  border-radius: 16px;
  & > h3 {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2F353C;
    border-bottom: 1px solid #F6EFAF;
    svg {
      margin-right: 14px;
    }
  }
  p {
    min-height: 34px;
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2F353C;

    strong {
      margin: 0 2px;
    }
  }
  & + [class*="assessmentFooterButton"] {
    width: 100%;
    margin-top: 24px;
    button {
      width: 160px;
    }
  }
}
.giveHint {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}