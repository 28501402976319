@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.btn{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: $primary-white;
  background: none;
  border: none;
  border-radius: 16px;
  outline: none;
  cursor: pointer;
  &[disabled] {
    background: linear-gradient(180deg, #B6C0C4 0%, #96A6AE 100%);
    box-shadow: none;
    cursor: not-allowed;
  }
}

.disableBtn{
  background: linear-gradient(180deg, #B6C0C4 0%, #96A6AE 100%) !important;
  box-shadow: none !important;
  cursor: auto;
}
.primary {
  @include flexVHC;
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, #01AFEA 0%, #0147FF 86.46%, #0172F6 100%);
  box-shadow: 0px 4px 12px rgba(144, 183, 215, 0.45), inset 0px 0px 10px #01AFEA;
}

.secondary{
  @include flexVHC;
  flex: 0 0 auto;
  width: 102px;
  height: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $primary-blue;
  border: 1px solid $primary-blue;
  border-radius: 16px;
  cursor: pointer;
}

.modalInfoButton{
  max-width: 464px;
  margin: auto;
}