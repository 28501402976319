.App {
  text-align: center;
  background: #F9F9FA;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.hanChar{
  color: #0185F3;
  cursor: pointer;
  padding: 0 1px;
}
.wordLine{
  display: flex;
  flex-wrap: wrap;
  position:relative;
  padding-bottom: 22px;
  align-items: baseline;
  gap: 4px;
}

.wordLine .transliterationWord_block{
  display: flex;
  flex-direction: column;
  margin: 0 4px;
}
.wordLine .transliterationWord_block p{
  position: static;
  text-align: center;
}
.wordLine span{
  display: block;
  width: 100%;
  max-width: 100%;
}
.wordAfter{
  position: absolute;
  bottom: 0;
  left: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  border: none;
  color: #97B8CB;
  width: 100%;
  justify-content: center;
  display: block;
  white-space: nowrap;
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
