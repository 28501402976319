.assessmentNotification {
  width: 100%;
  text-align: left;
  & + [class*="assessmentFooter"] {
    margin-top: 24px;
    & > div {
      width: 100%;
    }
  }
}
.notifySuccess {
  display: flex;
  align-items: center;
  width: 100%;
  height: 83px;
  padding: 0 16px;
  background: #D0FFE5;
  border: 1px solid #15C73C;
  border-radius: 10px;
  & > svg {
    width: 42px;
    height: 42px;
    margin-right: 10px;
  }
  & > div {
    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #0CB125;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #002846;
    }
  }
}
.notifyError {
  display: flex;
  flex-direction: column;
  border: 1px solid #F24455;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  header {
    display: flex;
    align-items: center;
    height: 74px;
    padding: 0 16px;
    background: #FFE6ED;
    svg {
      width: 42px;
      height: 42px;
      margin-right: 10px;
    }
    h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #F24455;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #002846;
    }
  }
  footer {
    display: flex;
    align-items: center;
    height: 39px;
    padding: 0 0 0 67px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #729BB4;
    background: #FFFFFF;
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #E91F2A;
      margin-left: 8px;
    }
  }
}