@import "src/assets/css/variables";

.editProfile{
  display: block;
  .userEmail{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #B6C0C4;
    pointer-events: none;
    padding-bottom: 16px;
    p{
      background: #F6F6F6;
      border: 1px solid #EEEEEE;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 8px;
    }
    span{
      display: block;
      margin-bottom: 8px;
      color: #2F353C;
    }
  }
  .changePassBtn{
    outline: none;
    border: none;
    width: 100%;
    display: flex;
    justify-content: center;
    background: none;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    color: $primary-blue ;
    padding: 12px 0 24px;
    cursor: pointer;
  }
  .changePassHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0 16px;
    margin-top: 12px;
    border-top: 1px solid #D0DFE8;

    p{
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #97B8CB;
    }
    button{
      outline: none;
      border: none;
      background: none;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: $primary-blue ;
      cursor: pointer;
    }
  }
}