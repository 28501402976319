@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.lessonLink {
  @include flexVHC;
  width: 100%;
  height: 52px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $primary-blue;
  text-decoration: none;
  background: $primary-white;
  box-shadow: 0px 4px 12px rgba(6, 44, 72, 0.15);
  border-radius: 16px;
}