@import "src/assets/css/mixins";

.statisticsContentWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 55px;
  margin-top: 32px;

  @include mobile{
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;

    .contentImage{
          order: -1;
    }
  }
  .contentText{
    p{
      font-size: 13px;
      line-height: 24px;
      &:not(:last-child) {
        margin-bottom: 20px;

        @include mobile{
          margin-bottom: 4px;
        }
      }
    }
  }
  .contentImage{
    width: 100%;
    img{
      max-width: 100%;
    }
  }

  button{
    max-width: 464px;
    margin: auto;
  }
}

.leaderboardContentWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:32px;
  align-items: center;
  margin: 32px 0;

  .leaderboardContentImage{
    width: 100%;
    text-align: center;
    img{
      max-width: 250px;
    }
  }
  .leaderboardContentText{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
}

.pointsContentWrapper{
  .pointsContentText{
    padding-top: 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;

    .listText{
      display: flex;
      justify-content: flex-start;
      column-gap: 57px;
      row-gap: 16px;
      margin-top: 18px;
      flex-wrap: wrap;

      ul{
        li{
          list-style: inside;

        }
      }
    }
  }
  .pointsContentImage{
    width: 100%;
    margin: 32px 0 37px;
      img{
        max-width: 100%;
        background-color: #EAF9FE;
        border-radius: 12px;
      }
  }
}