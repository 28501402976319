.mobileNavigation {
  display: none;
  //display: flex;
  position: fixed;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #002846;
  z-index: 9999;
  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 54px;
    padding: 0 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    & > [class*="logo"] {
      margin-left: 0;
    }
  }
  &[class*="isActive"] {
    display: flex;
  }
}
.navigationMain {
  padding: 24px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  [class*="headerProfileItems"] {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    & > a + a {
      margin: 32px 0 0 0!important;
    }
  }
}
.navigationProfile {
  padding: 0 16px 24px;

}
.navigationProfileItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 24px;
  text-decoration: none;
  & > svg {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
  }
  & > svg path,
  & > svg rect,
  & > svg circle {
    stroke: #FFFFFF;
  }
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }
  & + a {
    margin-top: 32px;
  }
  &[class*="profile"] {
    //background: pink;
  }
}
.profile {
  position: relative;
  width: calc(100% + 0px);
  height: 82px;
  //margin: 0 -16px;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  &::after {
    content: '';
    position: absolute;
    left: -16px;
    bottom: 0;
    right: -16px;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
  }
  [class*="avatarImage"] {
    width: 49px;
    height: 49px;
    margin-right: 16px;
  }
  & > svg {
    width: 20px;
    height: 20px;
    margin-left: auto;
    transform: rotate(-90deg);
  }
}
.buttonLogout {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 24px;
  &> svg{
    margin-right: 8px;
  }
  span{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
  }
}