@import "src/assets/css/mixins";
.coursesWrapper {
  width: 760px;
  padding: 32px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(61, 83, 116, 0.06);
  border-radius: 16px;
  @include tablet {
    width: 100%;
    padding: 12px;
  }
  & > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #2F353B;
    margin-bottom: 24px;
    text-align: left;
  }
}
.coursesSection {
  & > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2F353C;
    margin-bottom: 16px;
    text-align: left;
  }
  & + .coursesSection {
    margin-top: 32px;
  }
}
.coursesSectionItem {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #D0DFE8;
  box-sizing: border-box;
  border-radius: 10px;
  & > li {
    height: 40px;
  }
  & + .coursesSectionItem {
    margin-top: 16px;
  }
}
.coursesSectionItemLang {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;

  & > p {
    margin-left: 4px;
  }
}

.flagIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 34px;

  img {
    width: 34px;
    box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.35);
    filter: drop-shadow(0px 2px 6px rgba(6, 44, 72, 0.3));
    border-radius: 6px;
  }
}

.coursesSectionItemButtons {
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
}
.coursesButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0185F3;
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 0;
  &:hover {
    color: #01AFEA;
  }
  &:active {
    color: #0147FF;
  }
  &:target {
    color: #0185F3;
  }
  & + .coursesButton {
    margin-left: 16px;
  }
}
.coursesButtonSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 40px;
  padding: 0 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0185F3;
  border: 1px solid #0185F3;
  border-radius: 16px;
  cursor: pointer;
  background: transparent;
  &:hover {
    color: #01AFEA;
    border: 1px solid #01AFEA;
  }
  &:focus {
    color: #0147FF;
    border: 1px solid #0147FF;
  }
}