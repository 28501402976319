@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.leadershipBoardWrapper {
  width: 100%;
  max-width: 760px;
  padding: 32px;
  background: $primary-white;
  box-shadow: $onboard-wrapp-shadow;
  border-radius: $leadership-wrapp-radius;
  margin: 0 auto;

  @include mobile{
    padding: 0;
  }
  header {
    @include flexVC;
    justify-content: space-between;
    height: 24px;
    margin-bottom: 32px;

    @include mobile{
      padding: 8px 22px 8px 17px;
      gap: 6px;
      margin-top: 16px;
    }
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: $text-color;
      display: flex;

      @include mobile{
        font-size: 16px;
        line-height: 22px;

        span{
          flex: 1;
          text-align: left
        }
      }
    }
    & > p {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $text-color;
    }
    .leftDays{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      @include mobile{
        font-size: 15px;
        line-height: 22px;
        gap: 4px;
      }

      .infoIcon{
        cursor: pointer;
        display: flex;
        @include mobile{
          flex: 1;
          text-align: left
        }
      }
    }
  }
  .emptyLeagueText{
    display: flex;
    font-weight: 600;

    @include mobile{
      justify-content: center;
      font-size: 15px;
      padding: 0 16px;
      margin-bottom: 8px;
    }
  }
}

.leadershipList {
  position: relative;
  & > li {
    @include flexVC;
    justify-content: space-between;
    position: relative;
    height: 60px;
    & + li {
      margin-top: 4px;
    }
    &.up {
      .leadershipListPlace{
        color: $leadership-notify-bg;
      }
     .leadershipListScore{
       & > svg {
         border-radius: 50%;
         margin-left: 8px;
         width: 16px;
         height: 16px;
         path{
           fill: $leadership-notify-bg;
         }
       }
     }
    }
    &.down {
      .leadershipListPlace{
            color: $leadership-notify-drop;
      }
      .leadershipListScore{
        & > svg {
          transform: rotate(180deg);
          border-radius: 50%;
          margin-left: 8px;
          path{
            fill:$leadership-notify-drop;
          }
        }
      }
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: $leadship-list-color;
    }
  }
}
.leadershipListPlace {
  width: 40px;
  flex: 0 0 40px;
}
.leadershipListUser {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  svg,
  [class*="avatarImage"] {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $text-color;
    width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @include mobile{
      width: 85px;
    }
  }
}
.leadershipListScore {
  display: flex;
  justify-content: flex-end !important;
  gap: 8px;
  align-items: center;
  p{
    min-width: 50px;
    justify-content: flex-start!important;
  }
}
.currentUser {
  background: $leadership-user-bg;
  border-radius: $leadership-user-radius;
}
.scoreHigh {
  background: $leadership-score-high;
}
.scoreLow {
  background: $leadership-score-low;
}
li.leadershipBoardNotify {
  @include flexVC;
  justify-content: space-between;
  height: 48px!important;
  padding: 0 12px;
  border-radius: 10px;
  & > p {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    text-transform: uppercase;
  }
}