@import "src/assets/css/mixins";
.icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .flagIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0 26px 0;
    width: 72px;

    img {
      width: 72px;
      max-width: 100%;
      object-fit: contain;
      box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.35);
      filter: drop-shadow(0px 2px 6px rgba(6, 44, 72, 0.3));
      border-radius: 6px;
    }
  }
  @include mobile {
    justify-content: flex-start;
    .flagIcon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 16px 12px 16px 5px;

      img {
        width: 34px;
        min-width: 34px;
      }
    }

  }
}

.iconText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #2F353C;
  text-transform: capitalize;
  @include mobile {
    font-weight: 400;
    font-size: 13px;
  }
}