@import "src/assets/css/mixins";
.assessmentProgress {
  position: relative;
  display: block;
  width: 100%;
  height: 8px;
  margin-right: 3px;
  border-radius: 4px;
  //overflow: hidden;
  &::-webkit-progress-bar {
    background: #EEEEEE;
    border-radius: 4px;
  }
  &::-webkit-progress-value {
    background: #15C73C;
    border-radius: 4px 0 0 4px;
  }
  &[value="100"]::-webkit-progress-value {
    border-radius: 4px;
  }
}
.vocabularyWordList {
  text-align: left;
}
.wordRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  padding: 0 20px;
  margin-bottom: 16px;
  background: #FFFFFF;
  border: 1px solid #D0DFE8;
  border-radius: 16px;
  p {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #2F353C;
  }
}
.vocabularyQuiz {
  text-align: left;
  [class*="testKeyContent"] {
    margin-bottom: 16px;
  }
  [class*="wordRow"] {
    margin-bottom: 0;
  }
}


.prologuePage{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  //width: 420px;
  margin: 0 auto;
  h1 {
    width: 100%;
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #2F353C;
  }

  .lettersRow{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .lettersContent{
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      //width: calc(50% - 16px);
      width: auto;
      min-width: 60px;
      min-height: 60px;
      //max-width: calc(50% - 16px);
      padding: 10px;
      margin-bottom: 16px;
      background: #FFFFFF;
      box-shadow: 0px 1px 5px rgba(61, 83, 116, 0.08);
      border-radius: 10px;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        //width: 78px;
        min-width: 50px;
        height: auto;
        padding: 0 0 0 6px;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #729BB4;
        @include tablet {
          min-width: 59px;
        }
        &:first-child {
          padding: 0;
          color: #2F353C;
          background: #EAF9FE;
          border-radius: 6px;
        }
      }
    }
  }
}