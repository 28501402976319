.testKeyContent {
  width: 100%;

  .textInPtag{
    display: flex;
    align-items: center;
    height: 28px;
    font-style: normal;
    font-size: 14px;
    color: #002846;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 28px;
      padding: 0 8px;
      min-height: 28px;
      margin: 0 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #2F353C;
      background: #FFFFFF;
      border: 1px solid #0185F3;
      border-radius: 4px;
    }
  }

  .textWrapper{
    display: flex;
    align-items: baseline;
    font-style: normal;
    font-size: 14px;
    color: #002846;
    gap: 4px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 28px;
      padding: 0 8px;
      min-height: 28px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #2F353C;
      background: #FFFFFF;
      border: 1px solid #0185F3;
      border-radius: 4px;
    }
  }
}