@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.asideMenu {
  display: flex;
  flex-direction: column;
  width: 220px;
  margin-right: 25px;
  flex: 0 0 220px;
  @include tablet {
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
    margin: 0 0 8px 0;
    flex: 1 0 auto;
  }
}
.asideMenuItem {
  @include flexVC;
  justify-content: flex-start;
  width: 100%;
  height: 64px;
  padding: 0 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $text-color;
  text-decoration: none;
  background: #FFFFFF;
  box-shadow: 0 2px 12px rgba(61, 83, 116, 0.06);
  border-radius: 16px;
  cursor: pointer;
  text-align: left;
  @include tablet {
    flex-direction: column;
    justify-content: center;
    width: 116px;
    height: 102px;
    padding: 0;
    margin: 0 16px 16px 0!important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: $text-color;
    text-align: center;
    svg {
      margin: 0 0 8px 0!important;
    }
  }
  &:visited {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $text-color;
  }
  &:hover {
    background: #D3F2FF;
  }
  & + a {
    margin-top: 16px;
  }
  &.active {
    background: #D3F2FF;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 13px;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $text-color;
  }
}