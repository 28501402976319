@import "mixins";
@import "variables";

html,body,#root, .App {
  height: 100%;
}
body, p, h1, h2, h3, h4, ul, li, dd, dt, dl {
  margin: 0;
  padding: 0;

}

select,
textarea,
input {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    font-size: 16px!important;
  }

}

.App {
  @include mobile {
    display: flex;
    flex-direction: column;
  }
}
.App-content {
  height: auto !important;
  min-height: 100%;
  @include mobile {
    height: initial;
    min-height: initial;
  }
}
li {
  text-align: left;
  list-style: none;
}
*, *:before, *:after {
  box-sizing: border-box;
}
.link {
  &-regular {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-decoration: none;
    color: #0185F3;
    &:hover {
      text-decoration: underline;
    }
  }
  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
    background: linear-gradient(180deg, #01AFEA 0%, #0147FF 86.46%, #0172F6 100%);
    box-shadow: 0 4px 12px rgba(144, 183, 215, 0.45), inset 0 0 10px #01AFEA;
    border-radius: 16px;
    &:hover {
      background: #01AFEA;
      box-shadow: 0 4px 12px rgba(144, 183, 215, 0.45), inset 0 0 10px #01AFEA;
    }
    &:focus {
      background: #0147FF;
      box-shadow: 0 4px 12px rgba(144, 183, 215, 0.45), inset 0 0 10px #01AFEA;
    }
  }
}
