@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.onboardSlider {
  position: relative;
  &__item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 420px;
    padding-bottom: 24px;
    @include mobile {
      height: auto;
      flex-direction: column-reverse;
      justify-content: flex-end;
      flex: 1 0 auto;
    }
    &.step01 .onboardSlider__image {
      @include mobile {
        height: 363px;
      }
      span {
        width: 339px;
        height: 337px;
        background: url("../../../../assets/images/onboard-step01.png") 0 0 no-repeat;
        background-size: cover;
        @include mobile {
          width: 100%;
          height: 363px;
          background: url("../../../../assets/images/onboard-step01-mob.png") center 0 no-repeat;
          background-size: contain;
        }
      }
    }
    &.step02 .onboardSlider__image {
      padding-bottom: 40px;
      @include mobile {
        padding-bottom: 0;
        height: auto;
      }
      span {
        width: 329px;
        height: 279px;
        background: url("../../../../assets/images/onboard-step02.png") 0 0 no-repeat;
        background-size: cover;
        @include mobile {
          width: 311px;
          height: 363px;
          background: url("../../../../assets/images/onboard-step02-mob.png") center 0 no-repeat;
          background-size: contain;
        }
      }
    }
    &.step03 .onboardSlider__image {
      padding-bottom: 80px;
      @include mobile {
        padding-bottom: 0;
        height: 363px;
      }
      span {
        width: 231px;
        height: 232px;
        background: url("../../../../assets/images/onboard-step03.png") 0 0 no-repeat;
        background-size: cover;
        @include mobile {
          width: 100%;
          height: 363px;
          background: url("../../../../assets/images/onboard-step03-mob.png") center 0 no-repeat;
          background-size: contain;
        }
      }
    }
    &.step04 .onboardSlider__image {
      padding-bottom: 16px;
      @include mobile {
        padding-bottom: 0;
        height: auto;
      }
      span {
        width: 306px;
        height: 355px;
        background: url("../../../../assets/images/onboard-step04-mob.png") 0 0 no-repeat;
        background-size: cover;
        @include mobile {
          width: 311px;
          height: 363px;
          background-size: contain;
        }
      }
    }
    &.step05 .onboardSlider__image {
      padding-bottom: 60px;
      @include mobile {
        padding-bottom: 0;
      }
      span {
        width: 296px;
        height: 222px;
        background: url("../../../../assets/images/onboard-step05.png") 0 0 no-repeat;
        background-size: cover;
        @include mobile {
          width: 100%;
          height: 240px;
          background: url("../../../../assets/images/onboard-step05.png") center 0 no-repeat;
          background-size: contain;
        }
      }
    }
  }
  &__info {
    width: 320px;
    text-align: left;
    @include mobile {
      width: 100%;
      padding-top: 16px;
    }
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: $text-color;
      margin: 126px 0 19px;
      @include mobile {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin: 0 0 16px;
      }
      h2 + p {
        margin-top: 0;
      }
    }
    p {
      width: 100%;
      max-width: 297px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $text-color;
      margin-bottom: 16px;
      &:first-child {
        margin-top: 92px;
        @include mobile {
          margin-top: 0;
        }
      }
      @include mobile {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        max-width: 100%;
        margin-bottom: 8px;
      }
    }
  }
  &__image {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: calc(100% - 336px);
    span {
      display: flex;
    }
    @include mobile {
      width: 100%;
      height: 240px;
    }
  }
  .slick-dots-top {
    @include flexVC;
    justify-content: flex-start;
    width: calc(100% - 0px);
    height: 24px;
    top: -24px;
    margin: 0;
    li {
      width: 152px;
      height: 12px;
      background: $primary-blue;
      border-radius: 20px;
      overflow: hidden;
      margin: 0;
      &:first-child {
        display: none;
        & + li {
          margin-left: 0;
        }
      }
      & + li {
        margin-left: 4px;
      }
      &.slick-active {
        width: 152px;
        & ~ li {
          background: $input-border-color;
        }
      }
      button {
        display: none;
      }
    }
  }
  &-button {
    position: relative;
    @include flexVC;
    justify-content: space-between;
    width: 107px;
    height: 52px;
    padding: 0 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $text-color;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(6, 44, 72, 0.15);
    border: none;
    border-radius: 16px;
    cursor: pointer;
    &:hover {
      color: #01AFEA;
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        color: #0185F3;
      }
    }
    &:focus {
      color: #0147FF;
      @media screen and (-webkit-min-device-pixel-ratio:0) {
        color: #0185F3;
      }
    }
    svg {
      width: 24px;
      height: 24px;
    }
    @include mobile {
      background: #FCFCFC;
      box-shadow: none;
      border-radius: 0;
    }
  }
}