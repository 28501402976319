.table{
  border-collapse: collapse;
  border-spacing: 0;

  .td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 0 4px;
  }
}
