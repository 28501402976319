@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, ul, li {
  margin: 0;
  padding: 0;
}
li {
  text-align: left;
  list-style: none;
}
*, *:before, *:after {
  box-sizing: border-box;
}
