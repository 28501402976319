.testWrapper {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  text-align: left;
}
.testContent {
  width: 100%;
  max-width: 734px;
  min-height: 500px;
  padding: 32px 40px;
  background: #FCFCFC;
  border-radius: 16px;
  @media screen and (max-width: 640px) {
    padding: 16px;
  }
  & > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #2F353B;
    margin-bottom: 8px;
  }
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2F353C;
  }
  [class*="button_primary"] {
    width: 100%;
    max-width: 464px;
    margin: 0 auto;
  }
}
.testComplete {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #2F353B;
    margin-bottom: 16px;
  }
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2F353C;
    text-align: center;
    margin-bottom: 32px;
  }
  & > a {
    margin-bottom: 32px;
  }
  & > span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #729BB4;
    text-align: center;
  }
}
.testCompleteImage {
  width: 215px;
  height: 216px;
  margin-bottom: 24px;
  background: url("../../assets/images/congratulations.png") 0 0 no-repeat;
  background-size: cover;
}
