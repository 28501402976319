.singMethodsWrapper {
  display: flex;
  justify-content: space-between;
  width: 224px;
  height: 60px;
}
.singMethodsItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(6, 44, 72, 0.1);
  border-radius: 16px;
  cursor: pointer;
  span {
    display: flex;
    width: 32px;
    height: 32px;
  }
}
.apple {
  span {
    background: url("../../assets/images/icon-apple.png") 0 0 no-repeat;
    background-size: cover;
  }
}
.google {
  span {
    background: url("../../assets/images/icon-google.png") 0 0 no-repeat;
    background-size: cover;
  }
}
.facebook {
  span {
    background: url("../../assets/images/icon-facebook.png") 0 0 no-repeat;
    background-size: cover;
  }
}