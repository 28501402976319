$desktop-min-width: 1024px;
$mobile-max-width: 768px;

@mixin tablet {
  @media screen and (max-width: #{$desktop-min-width}) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: #{$mobile-max-width}) {
    @content;
  }
}

@mixin flexVC {
  display: flex;
  align-items: center;
}
@mixin flexHC {
  display: flex;
  justify-content: center;
}
@mixin flexVHC {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin imageFitCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}