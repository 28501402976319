@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.completeLesson {
  @include flexVC;
  flex-direction: column;
  & > h3 {
    font-style: normal;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: $text-color;
    margin-bottom: 16px;
  }
  & > h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $text-color;
    margin: 0 0 24px;
  }
}
.image {
  width: 215px;
  height: 216px;
  margin-bottom: 24px;
  background: url("../../../../assets/images/congratulations.png") 0 0 no-repeat;
  background-size: cover;
}
.award {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 16px;
  background: #EBFDF3;
  border-radius: 10px;
  margin-bottom: 32px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #729BB4;
  }
  strong {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: #002846;
  }
}