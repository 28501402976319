@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.billingModal {
  width: 100%;
  padding-top: 32px;
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: $text-color;
  }
  button {
    margin-top: 16px;
  }

  .billingPrivacy{
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    display: block;
    width: 100%;
    text-decoration: none;
    color: $primary-blue;
    margin-top:24px;
  }
}
.billingPlansItem {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  padding: 8px;
  background: #EBFDF3;
  border-radius: 16px;
  svg {
    width: 60px;
    min-width: 60px;
    height: 60px;
    margin-right: 16px;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $text-color;
    margin-bottom: 4px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: $text-color;
    margin-bottom: 2px;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #729BB4;
  }
  .billingPlansItemContent {
    display: flex;
    flex-direction: column;

    h2{
      font-weight: 700;
    }
  }
}
.billingImages {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 0 24px;
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #323232;
    padding-bottom: 24px;
  }
  & > svg {
    width: 116px;
    height: 116px;
  }
}
.billingImagesCards {
  display: flex;
  justify-content: space-between;
  width: 180px;
  height: 32px;
  svg {
    width: 48px;
    height: 32px;
  }
}

.successImage {
  width: 215px;
  height: 216px;
  margin-bottom: 24px;
  background: url("../../../../assets/images/congratulations.png") 0 0 no-repeat;
  background-size: cover;
}

.cancelImage {
  width: 215px;
  height: 216px;
  margin-bottom: 24px;
  background: url("../../../../assets/images/error.png") 0 0 no-repeat;
  background-size: cover;
}
.loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(7px);
  background: #7d7a8a36;
}

