@import "src/assets/css/mixins";
.flagContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .flagIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    height: 68px;

    img {
      height: 68px;
      max-width: 100%;
      object-fit: contain;
      box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.35);
      filter: drop-shadow(0px 2px 6px rgba(6, 44, 72, 0.3));
      border-radius: 6px;
    }
  }
}
.sideBySideItems {
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
  box-sizing: border-box;

  @include mobile {
    flex-wrap: wrap
  }

  .colItem {
    padding-left: 12px;
    padding-right: 12px;
    @include mobile {
      width: 100%;
      flex-wrap: wrap;
      & + .colItem {
        margin-top: 16px;
      }
    }
  }


}
.selectCourseModal {
  cursor: pointer;
  [class*="flagContainer"] {
    margin-bottom: 16px;
  }
}