@import "src/assets/css/mixins";
.profileGoal {
  padding: 16px 32px 24px;
  @include mobile {
    padding: 0 0 16px;
  }
}
.profileGoalItem {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 77px;
  padding: 8px 20px 8px 12px;
  background: #FFFFFF;
  border: 1px solid #D0DFE8;
  box-sizing: border-box;
  border-radius: 16px;

  form{
    display: flex;
    align-items: center;
    width: 100%;
    [class*="inputWrapper"] {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex: 1 0 auto;
      padding-bottom: 0;
      [class*="inputField"] {
        font-size: 13px;
        border: none;
        padding: 0 20px 0 0;
      }

      & ~ [class*="secondary"] {
        width: 85px;
        min-width: 85px;
        height: 40px;
        margin: 0 6px;
        flex: 0 0 auto;
      }
      & ~ [class*="primary"] {
        width: 76px;
        height: 40px;
        flex: 0 0 auto;
      }
    }
    [class*="inputWrapperError"] input {
      background: none;
    }
    [class*="inputWrapperError"] p {
      bottom: -8px;
      left: 0;
    }
    [class*="button_secondary"] {
      min-width: 105px;
    }
  }
}
.profileGoalIcon {
  flex: 0 0 auto;
  width: 53px;
  height: 53px;
  margin-right: 10px;
  background: url("../../assets/images/goal-targetgoal-target.png") 0 0 no-repeat;
  background-size: cover;
}
.profileGoalText {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 8px 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #2F353C;
  text-align: left;
  border: none;
  word-break: break-word;
}
.profileGoalButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 102px;
  height: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0185F3;
  border: 1px solid #0185F3;
  border-radius: 16px;
  cursor: pointer;
}
.profileGoalMobile {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  z-index: 6;
  &::before {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgb(0, 40, 70);
    z-index: 5;
  }
  [class*="profileGoalItem"] {
    min-height: 246px;
    z-index: 6;
    form [class*="inputWrapper"] [class*="inputField"] {
      padding: 0;
      height: 130px;
    }
  }
  [class*="profileGoalIcon"] {
    display: none;
  }
  form {
    flex-wrap: wrap;
    justify-content: space-between;
    [class*="inputWrapperError"] p {
      bottom: -19px;
    }
  }
  [class*="inputWrapper"] {
    align-items: flex-start!important;
    width: 100%;
    height: 146px;
    padding: 8px;
    margin-bottom: 24px;
    border: 1px solid #0185F3;
    border-radius: 10px;
  }
  button {
    width: calc(50% - 8px)!important;
  }
}