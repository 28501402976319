@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.billingWrapper {
  width: 100%;
  max-width: 760px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  background: #FFFFFF;
  box-shadow: 0 2px 12px rgba(61, 83, 116, 0.06);
  border-radius: 16px;
  padding:32px;
  margin-bottom: 46px;

  .showMoreBtn{
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    width: 100%;
  }
  @include tablet {
    max-width: 100%;
  }
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #2F353B;
    margin-bottom: 4px;
  }
  & > p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #2F353B;
    margin-bottom: 40px;
  }
}
.billingPlans {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  margin-bottom: 46px;
  @include mobile {
    flex-direction: column;
  }
  & + h2 {
    margin-bottom: 32px;
  }
}
.billingPlansItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100% / 4);
  padding: 16px 8px;
  border: 1px solid #D0DFE8;

  &.billingPlansModalItem{
    width: calc(100% / 3);
  }

  @include mobile {
    width: 100%;
    flex-direction: row;
    border-radius: 16px;
    flex-wrap: wrap;

    &.billingPlansModalItem{
      width: 100%;
    }

    & + .billingPlansItem {
      margin-top: 16px;
    }
  }
  &:first-child {
    border-radius: 16px 0 0 16px;
    @include mobile {
      border-radius: 16px;
    }
  }
  &:last-child {
    border-radius: 0 16px 16px 0;
    @include mobile {
      border-radius: 16px;
    }
  }
  &:hover {
    background: #EAF9FE;
    border: 1px solid #0185F3;
    .selectBTN {
      color: #FFFFFF;
      background: linear-gradient(180deg, #01AFEA 0%, #0147FF 86.46%, #0172F6 100%);
      box-shadow: 0px 4px 12px rgba(144, 183, 215, 0.45), inset 0px 0px 10px #01AFEA;
    }
  }
  svg {
    width: 60px;
    min-width: 60px;
    height: 60px;
  }
  .billingPlansItemContent {
    display: flex;
    flex-direction: column;
    padding: 12px 0 0 0;
    @include mobile {
      padding: 0;
    }
  }
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #2F353B;
    padding: 0 8px;
    margin-bottom: 29px;
    @include mobile {
      margin-bottom: 8px;
    }
  }
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #2F353B;
    padding: 0 8px;
    margin-bottom: 4px;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #729BB4;
    padding: 0 8px;
    margin-bottom: 29px;
    @include mobile {
      margin-bottom: 0;
    }
  }
  .selectBTN {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: $primary-blue;
    text-decoration: none;
    cursor: pointer;
    margin: 0 8px;
    border: 1px solid $primary-blue;
    border-radius: 16px;
    @include mobile {
      width: 100%;
      margin-top: 8px;
    }
  }
}
.billingShevron {
  position: absolute;
  top: 16px;
  right: -6px;
  width: 89px;
  height: 21px;
  background: url("../../../assets/images/shevron.png") 0 0 no-repeat;
  background-size: contain;
  z-index: 2;
}
.billingHistory {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 435px;
}
.billingHistoryItem {
    display: flex;
  & > div {
    @include flexVC;
    height: 32px;
    & + div {
      margin-left: 8px;
    }
    a {
      display: flex;
      svg {
        width: 16px;
        height: 16px;
        margin-left: 8px;
      }
    }
    &:nth-child(1) {
      width: 100%;
      max-width: 170px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
    &:nth-child(2) {
      width: 60px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
    &:nth-child(3) {
      width: 100px;
    }
    &:nth-child(4) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }
  & + li {
    margin-top: 24px;
  }
  .billingStatus {
    color: initial;
    span {
      @include flexVHC;
      min-width: 32px;
      height: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      padding: 0 4px;
      border-radius: 4px;
    }
    .succeeded {
      color: #0E6245;
      background: #CBF4C9;
    }
    .failed {
      color: white;
      background: #E91F2A;
    }
  }
}
.currentPlanWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  h2 {
    margin-bottom: 24px;
  }
}
.currentPlan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px;
  border: 1px solid $input-border-color;
  border-radius: 16px;
  @include mobile {
    flex-wrap: wrap;
  }
}
.currentPlanInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 0 auto;
  @include mobile {
    max-width: 100%;
    width: 100%;
  }
    span {
    @include flexVHC;
    height: 17px;
    padding: 4px 8px;
    background: #FF9F0A;
    border-radius: 16px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 800;
    font-size: 8px;
    line-height: 9px;
    text-align: center;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: $primary-white;
  }
  h3 {
    @include flexHC;
    height: 27px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: $text-color;
    margin-bottom: 4px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #729BB4;
    flex-wrap: wrap;
  }
}
.currentPlanPrice {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #729BB4;
  @include mobile {
    justify-content: center;
    flex: 0 0 auto;
    width: 100%;
    margin: 0;
    padding: 16px 0;
  }
  strong {
    font-weight: 700;
    font-size: 18px;
    color: #2F353B;
  }
}
.currentPlanButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 118px;
  width: 118px;
  @include mobile {
    width: 100%;
    flex: 0 0 auto;
  }
  button {
    width: 100%;
    height: 40px;
    font-size: 14px;
    & + button {
      margin-top: 8px;
    }
  }
}

.paymentMethodWrapper {
  width: 100%;
  margin-bottom: 40px;
  h2 {
    margin-bottom: 16px;
  }
}
.paymentMethod {
  position: relative;
  width: 260px;
  padding: 24px;
  border: 1px solid #D0DFE8;
  border-radius: 16px;
}
.paymentMethodContent {
  display: flex;
  flex-wrap: wrap;
  max-width: 185px;
  p {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #729BB4;
    &:first-child {
      @include flexVC;
      justify-content: space-between;
      color: #2F353B;
      margin-bottom: 16px;
    }
  }
  span {
    @include flexVHC;
    height: 16px;
    font-weight: 400;
    font-size: 10px;
    color: #000000;
    background: #D0DFE8;
    opacity: 0.5;
    padding: 0 4px;
    border-radius: 4px;
  }
}
.close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
}