@import "src/assets/css/mixins";
.tabletProfileMenu {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 228px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(61, 83, 116, 0.06);
  border-radius: 16px;
  z-index: 5;
  ul {
    padding: 16px 0;
    li + li {
      margin-top: 16px;
    }
  }
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2F353C;
    text-decoration: none;
    &.active {
      background: #D3F2FF;
    }
    svg {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
}