@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.dropdown {
  position: relative;
  user-select: none;
  &[class*="isActive"] {
    dt > svg {
      transform: translateY(-50%) rotate(180deg);
    }
    dd {
      display: block;
    }
  }
}
.dropdownLabel {
  display: flex;
  position: relative;
  cursor: pointer;
  & > div{
    display: flex;
    align-items: center;

  }
  & > svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(0deg);
  }
  .flagIcon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) rotate(0deg);
    img {
      width: 34px;
      box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.35);
      filter: drop-shadow(0px 2px 6px rgba(6, 44, 72, 0.3));
      border-radius: 6px;
    }
  }
}
.dropdownBody {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
}
.dropdownItems > li {
  display: block;
  position: relative;
  cursor: pointer;
}


.dropdown {
  // styles for basic dropdown
  &[data-style*="basic"] {
    width: 105px;
    height: 44px;
    background: $primary-white;
    border: 1px solid $input-border-color;
    border-radius: 10px;
    z-index: 3;
    &[class*="isActive"] {
      border-radius: 10px 10px 0 0;
    }
    & > dt {
      height: 100%;
      padding: 0 24px 0 12px;
      cursor: pointer;
     p {
       font-style: normal;
       font-weight: 400;
       font-size: 14px;
       line-height: 18px;
       color: $text-color;
       text-align: left;
     }
      & > svg {
        width: 12px;
        height: 6px;
        right: 12px;
        path {
          stroke: $primary-blue;
        }
      }
    }
    & > dd {
      right: -1px;
      left: -1px;
      background: $primary-white;
      padding: 8px 0;
      border: 1px solid $input-border-color;
      border-radius: 0 0 10px 10px;
      li {
        display: flex;
        align-items: center;
        height: 32px;
        padding: 0 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $text-color;
        & + li {
          margin-top: 4px;
        }
      }
    }
  }

  // styles for dropdown om header
  &[data-style*="header"] {
    width: 220px;
    background: transparent;
    border: 1px solid rgba(124, 144, 160, 0.8);
    border-radius: 8px;
    @include tablet {
      width: auto;
    }
    @include mobile {
      width: 56px;
      height: 36px;
      margin-left: auto;
    }
    & > dt {
      justify-content: space-between;
      padding: 4px 32px 4px 4px;
      cursor: pointer;
      @include mobile {
        justify-content: center;
        padding: 0;
      }
      div {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        color: $primary-white;
        @include tablet {
          text-indent: -9999999px;
          width: 52px;
          overflow: hidden;
          span {
              display: none;
          }
        }
        @include mobile {
          width: auto;
        }
        & > svg {
          width: 52px;
          height: 40px;
          fill: #D0DFE8;
          @include mobile {
            width: 44px;
            height: 34px;
          }
        }
      }
      & > svg {
        right: 12px;
        width: 14px;
        height: 7px;
      }
    }
    & > dd {
      margin: 4px 0 0;
      padding: 12px;
      background: #002846;
      border: 1px solid #637B8E;
      border-radius: 8px;
      @include tablet {
        width: 182px;
      }
      @include mobile {
        width: 56px;
      }
      & > ul li {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: $primary-white;
        text-align: left;
        margin-bottom: 8px;
        @include tablet {
          width: 100%;
          overflow: hidden;
        }
        @include mobile {
          justify-content: center;
          span {
            display: none;
          }
        }
        svg {
          width: 52px;
          height: 40px;
          margin-right: 4px;
          @include mobile {
            margin-right: 0;
          }
        }
      }
    }
    .addItem {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #01AFEA;
      text-decoration: none;
      @media screen and (max-width: 1130px) and (min-width: 1024px) {
        font-size: 12px;
      }
      @include tablet {
        justify-content: center;
        width: 100%;
        padding: 0;
        overflow: hidden;
      }
      @include mobile {
        span {
          display: none;
        }
      }
      & > i {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 24px;
        margin: 0 12px 0 0;
        border: 2px solid #01AFEA;
        box-sizing: border-box;
        border-radius: 6px;
        @include mobile {
          margin: 0;
        }
      }

    }
  }
}