@import "src/assets/css/mixins";
.notification {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  width: 475px;
  height: fit-content;
  flex-direction: column;
  min-height: 150px;
  padding: 16px;
  z-index: 9999999999;
  border-radius: 16px;
  background-color: #FFE6F2;
  transform: translate(-50%, -50%);
  @include mobile {
    width: calc(100% - 150px);
  }
}
.notificationIcon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  flex: 0 0 auto;
}
.notificationRow {
  margin-top: auto;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.notificationText {
  text-align: left;
}
.notificationConfirm {
  cursor: pointer;
  background-color: #FFE6F2;
  color: #991B1B;
  border: 1px solid #991B1B;
  border-radius: 10px;
  padding: 8px 16px;
}
.notificationClose {
  cursor: pointer;
  margin-left: auto;
  background-color: #ECFDF5;
  color: #065F46;
  border: 1px solid #065F46;
  border-radius: 10px;
  padding: 8px 16px;
}
.error {
  background-color: #D3F2FF;
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    a {
      font-weight: 500;
    }
  }
  & > div svg,
  & > div svg path {
    color: #F87171 !important;
    fill: #F87171 !important;
  }
}