@import "src/assets/css/mixins";

.textContent {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2F353C;
  position: relative;

  ul {
    padding: 0;

    li {
      list-style: disc inside;
    }
  }

  ol {
    padding: 0;

    li {
      list-style: decimal inside;
    }
  }

  h4 {
    font-size: 18px;
    margin-bottom: 8px;
    @include mobile {
      font-size: 16px;
    }
  }

  h5 {
    font-size: 16px;
  }

  p {
    font-size: 16px;

    @include mobile {
      font-size: 14px;
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}