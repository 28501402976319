@import "src/assets/css/mixins";
.welcomePageWrapper {
  @include flexVHC;
  width: 100%;
  padding: 64px 0 32px;
  @include mobile {
    padding: 16px;
  }
}
.welcomePageMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 734px;
  height: 570px;
  padding: 64px 60px 80px;
  background: #FCFCFC;
  border-radius: 16px;
  @include mobile {
    padding: 16px;
  }
}
.welcomePageLogo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 208px;
  @include mobile {
    height: 140px;
  }
}
.welcomePageLogoImage {
  width: 192px;
  height: 148px;
  background: url("../../../assets/images/logo-blue.png") 0 0 no-repeat;
  background-size: cover;
}
.welcomePageInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 295px;
  height: 218px;
  padding: 32px 32px 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(61, 83, 116, 0.06);
  border-radius: 16px;
  @include mobile {
    width: 100%;
    height: auto;
    padding: 16px;
    & + [class*="welcomePageInfo"] {
      margin-top: 16px;
    }
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    text-align: center;
  }
}
