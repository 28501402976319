@import "src/assets/css/variables";

.scriptTestKeyContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;

  .lettersBlock{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2px;
    p{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      background: #F6F6F6;
      border: 1px solid #EEEEEE;
      border-radius: 4px;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border: 1px solid #E91F2A;
      border-radius: 4px;
    }
    div{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #97B8CB;
    }

    .blueBlock{
      border: 1px solid $primary-blue;
    }
  }
  p{
    display: flex;
    align-items: center;
    height: 28px;
    font-style: normal;
    font-size: 14px;
    color: #002846;
  }
  button{
    margin-left: 28px;;
  }
}