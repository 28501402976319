@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.boxContent {
 background: $box-bg;
  border-radius: 16px;
  overflow: hidden;
  & > h4 {
    @include flexVC;
    justify-content: flex-start;
    width: 100%;
    height: 48px;
    padding: 0 12px;
    border-bottom: 1px solid $box-border-color;
    svg {
      width: 24px;
      margin-right: 16px;
    }
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $text-color;
    }
  }
  & > div {
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $text-color;
  }
  & > h4 + div {
    padding: 6px 12px 12px;
  }
}
.orange{
  background: #FFFBDA !important;
}
.gray{
  background: #F6F6F6 !important;
  & > h4 {
    border-bottom: 1px solid $primary-white;
  }
}
.blue{
  background: #01AFEA !important;
  & > h4 {
    border-bottom: 1px solid $primary-white;
    p {
      color: $primary-white;
    }
  }
  & > div {
        color: $primary-white;
  }
}