@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.modalIMage{
  @include flexVHC;
  flex-direction: column;
  margin-top: 16px;
  input {
    display: none;
  }
  .imageContainer {
    position: relative;
    display: inline-block;
    width: 121px;
    height: 155px;
    label {
      width: 100%;
      height: 100%;
      display: block;
      cursor: pointer;
      img, svg {
        width: 121px;
        height: 121px;
        object-fit: cover;
        border-radius: 50%;
      }
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: $primary-blue;
        text-align: center;
        margin: 8px 0 0 0;
      }
    }
    button {
      @include flexVHC;
      position: absolute;
      top: -10px;
      right: -10px;
      width: 24px;
      height: 24px;
      padding: 0;
      background: rgba(0, 40, 70, 0.75);
      border: none;
      outline: none;
      border-radius: 8px;
      cursor: pointer;
      &::before,
      &::after {
        position: absolute;
        left: 11px;
        content: ' ';
        height: 14px;
        width: 2px;
        background-color: $primary-white;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
.errorMessage {
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: $error;
}