@import "src/assets/css/mixins";
.langToLearnDescription {
  display: flex;
  width: 552px;
  height: 203px;
  margin: 106px 0 40px 0;
  padding: 24px 32px;
  background: linear-gradient(0deg, rgba(0, 40, 70, 0.2), rgba(0, 40, 70, 0.2)), linear-gradient(90deg, #2196F3 0%, #104FF0 100%);
  border-radius: 16px;
  @include tablet {
    margin: 32px 0 0;
  }
  @include mobile {
    width: 100%;
    padding: 16px 0;
    margin: 0 0 16px;
    overflow: hidden;
  }
}
.langToLearnDescriptionImage {
  width: 154px;
  height: 154px;
  margin-right: 40px;
  background: url("../../assets/images/logo-description.png") 0 0 no-repeat;
  background-size: cover;
  @include mobile {
    width: 24%;
    margin-right: 16px;
    background: url("../../assets/images/logo-description.png") 100% 0 no-repeat;
    background-size: cover;
  }
}
.langToLearnDescriptionText {
  padding-top: 4px;
  @include mobile {
    width: 75%;
    padding: 0 16px 0 0!important;
  }
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 10px;
    @include mobile {
      font-size: 16px;
      text-align: left;
    }
  }
  ul {
    li {
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
      padding: 0 0 0 20px;
      @include mobile {
        font-size: 12px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        height: 14px;
        background: url("../../assets/images/desciption-item.png") 0 0 no-repeat;
        background-size: cover;
        @include mobile {
          top: 1px;
        }
      }
      & + li {
        margin-top: 16px;
        @include mobile {
          margin-top: 8px;
        }
      }
    }
  }
}