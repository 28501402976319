@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.authWrapper {
  @include flexVC;
  flex-direction: column;
  padding: 70px 0;
  @include mobile {
    flex: 1 0 auto;
    justify-content: flex-start;
    padding: 0 0 32px;
  }
}
.authSection {
  width: 578px;
  //height: 644px;
  padding: 32px;
  background: $authSection-bg;
  border-radius: 16px;
  @include tablet {
    width: 528px;
  }
  @include mobile {
    width: 100%;
  }
}
.authSectionTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: $auth-text-color;
  text-align: center;
  margin-bottom: 40px;
}
.authAgreements {
  @include flexVC;
  justify-content: flex-start;
  padding: 8px 0 24px;
  label {
    @include flexVC;
    justify-content: flex-start;
    height: 16px;
    position: relative;
    padding: 0 0 0 26px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background: $primary-white;
      border: 1px solid $input-border-color;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  input {
    display: none;
    &:checked + label::before {
      border: none;
      background: url("../../../assets/images/checkbox-checked.png") 0 0 no-repeat;
      background-size: cover;
    }
  }
  span, a {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $text-color;
  }
  a {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: $primary-blue;
    margin-left: 4px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.authMethods {
  @include flexVHC;
  flex-direction: column;
  margin: 26px 0 0 0;

  .loginInfoText{
    font-size: 14px;
    line-height: 19px;
    color: rgba(47, 53, 59, 0.6);
    margin-bottom: 16px;
  }
}
.authMethodsText {
  position: relative;
  @include flexVHC;
  width: 100%;
  height: 19px;
  margin-bottom: 24px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background: #E1EAF0;
    z-index: 1;
  }
  span {
    position: relative;
    display: flex;
    height: 19px;
    padding: 0 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(47, 53, 59, 0.6);
    background: $authSection-bg;
    z-index: 2;
  }
}
.authForgotPass {
  @include flexVC;
  justify-content: flex-end;
  height: 18px;
  padding: 8px 0 16px;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-decoration: none;
    color: $primary-blue;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.authRecoverPassText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: $text-color;
  margin-bottom: 40px;
}
.authFooter {
  @include flexVC;
  width: 528px;
  height: 18px;
  margin-top: 24px;
  @include mobile {
    width: 100%;
    justify-content: center;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $text-color;
  }
  span:last-child {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: $primary-blue;
    margin-left: 4px;
    cursor: pointer;
  }
}

.sendAgainTxt{
  justify-content: center;
  padding: 0 16px;
}

.checkEmailBlock {
  @include flexVC;
  flex-direction: column;
  & + form {
    display: none;
  }
  padding: 16px;
}
.checkEmailImage {
  width: 116px;
  height: 116px;
  margin-bottom: 24px;
  background: url("../../../assets/images/icon-recovery-flow.png") 0 0 no-repeat;
  background-size: cover;
}
.checkEmailText {
  width: 100%;
  max-width: 412px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: $text-color;
  margin-bottom: 40px;
}
.verifyEmailText{
  max-width: 508px;
}