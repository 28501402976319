@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.audioContentSound {
  @include flexVHC;
  width: 48px;
  min-width: 48px;
  height: 48px;
  background: $primary-blue;
  border: none;
  outline: none;
  box-shadow: 0px 2px 8px rgba(144, 183, 215, 0.45);
  border-radius: 100%;
  cursor: pointer;
}