@import "src/assets/css/mixins";
.leadershipSidebar {
  position: sticky;
  top: 96px;
  align-self: flex-start;
  z-index: 3;
  gap: 16px;

  @include tablet {
    position: relative;
    width: 100% !important;
    justify-content: center;
    top: 0;
    gap: 10px
  }

  @include mobile{
    gap: 0;
    width: 100% !important;
  }

  [class*="sidebar_asideMenuItem"] svg {
    width: 48px;
    height: 48px;
    min-width: 48px;
  }

  .leanderBoardSidebarItem{
    @include mobile{
      width: 100%;
      padding: 0 5px;
    }
    & > div{
      @include mobile{
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: 0;
        padding: 10px;
        text-align: center;
      }
      & > svg{
        @include mobile{
          width: 40px;
          height: 40px;
          margin: 0;
        }
      }
    }
  }
}
.svgBlend {
  mix-blend-mode: color;
}