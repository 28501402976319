@import "src/assets/css/mixins";

.toolTipMainPanel{
  position: absolute;
  background-color: transparent;
  padding-top: 19px;
  width: 100%;
  max-width: 100%;
  left: 0;
  border-radius: 19px;
  z-index: 9;
  border-bottom: 1px solid #0185F3;
  box-shadow: 6px 21px 13px -15px #01afea;

  .toolTipMain{
    width: 100%;
    background: #0185F3;
    border-radius: 16px;
    z-index: 2;
    left: 2px;
    position: relative;
    .contentInner{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 14px 20px;
      .contentDescription{
        max-width: 65%;
        width: 100%;
        @media only screen and (max-width: 576px){
          max-width: 100%;
        }
        .contentHeadline{
          *{
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: #FFFFFF;
          }
        }
        .contentDescriptionTxt{
          *{
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #EAF9FE;
          }
        }
      }
      .contentReading{
        max-width: 35%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 6px;
        align-items: flex-start;
        align-content: flex-start;
        @media only screen and (max-width: 576px){
          max-width: 100%;
          justify-content: flex-start;
          margin-top: 15px;
        }
        span{
          display: inline-block;
          padding: 5px 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #2F353C;
          background: #EAF9FE;
          border: 1px solid #EEEEEE;
          border-radius: 4px;
        }
      }
      .contentWords{
        max-width: 100%;
        width: 100%;
        margin-top: 16px;
        .wordLine{
          background: #EAF9FE;
          border: 1px solid #D0DFE8;
          border-radius: 10px;
          padding: 17px 96px 17px 16px;
          position: relative;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          @include mobile{
            padding-right: 40px;
          }
          &:not(:first-child){
            margin-top: 10px;
          }
          p{
            padding: 0 5px;
            max-width: 33.333333%;
            width: 100%;
            @include mobile{
              max-width: 100%;
            }
          }
          .audioButton{
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

  }
  .triangle{
    clip-path: polygon(50% 45%, 0% 100%, 100% 100%);
    background-color: #0185F3;
    width: 50px;
    height: 19px;
    top: 0;
    left: 20px;
    position: absolute;
  }
  .triangleAfter{
    width: 50px;
    height: calc(100% - 18px);
    position: absolute;
    top: 19px;
    z-index: -1;
    background: #0185F3;
    border-radius: 0 0 16px 16px;
  }
}