@import "src/assets/css/mixins";
.logo {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  justify-content: space-between;
  width: 150px;
  @include tablet {
    width: 40px;
  }
  @include mobile {
    width: 120px;
    margin-left: 16px;
  }
}
.logoImage {
  width: 40px;
  height: 40px;
  background: url("../../assets/images/app-logo.png") 0 0 no-repeat;
  background-size: cover;
  @include mobile {
    width: 32px;
    height: 32px;
  }
}
.logoName {
  width: 100px;
  height: 20px;
  background: url("../../assets/images/app-name.png") 0 0 no-repeat;
  background-size: cover;
  @include mobile {
    width: 78px;
    height: 16px;
  }
}