@import "src/assets/css/mixins";
.notification {
  position: absolute;
  right: 24px;
  bottom: 82px;
  display: flex;
  align-items: center;
  width: 475px;
  height: fit-content;
  padding: 16px;
  z-index: 9999999999;
  border-radius: 16px;
  @include mobile {
    width: calc(100% - 64px);
    right: 32px;
    left: 32px;
  }
}
.notificationIcon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  flex: 0 0 auto;
}
.notificationText {
  text-align: left;
}
.notificationClose {
  width: 20px;
  height: 20px;
  flex: 0 0 auto;
  cursor: pointer;
  margin-left: auto;
}
.error {
  background: #FEF2F2;
  & > span {}
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #991B1B;
    a {
      font-weight: 500;
    }
  }
  & > div svg,
  & > div svg path {
    color: #F87171 !important;
    fill: #F87171 !important;
  }
}
.success {
  background: #ECFDF5;
  & > p {
    color: #065F46;
  }
  & > div svg,
  & > div svg path {
    color: #10B981 !important;
    fill: #10B981 !important;
  }
}