@import "src/assets/css/variables";
@import "src/assets/css/mixins";

.audioContent {
  @include flexVC;
  justify-content: space-between;
  width: 100%;
  min-height: 68px;
  padding: 0 20px;
  background: $primary-white;
  border: 1px solid #D0DFE8;
  border-radius: 16px;
  p {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #2F353C;
    & + p {
      font-weight: 400;
    }
  }
}

.audioContentTranslate {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: $primary-blue;
  cursor: pointer;
}