.testContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2F353C;
    margin-bottom: 32px;
    padding-right: 75px;
  }
}
.testSectionList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px 0 24px;
  & > li {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 12px;
    input {
      display: none;
      &:checked + label {
        background: #EAF9FE;
        border: 2px solid #0185F3;
        box-shadow: 0px 1px 5px rgba(61, 83, 116, 0.08);
        border-radius: 10px;
        svg {
          display: block;
          position: absolute;
          top: 50%;
          right: 16px;
          width: 24px;
          height: 24px;
          transform: translateY(-50%);
        }
      }
    }
    label {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 0 16px;
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      box-shadow: 0px 1px 5px rgba(61, 83, 116, 0.08);
      border-radius: 10px;
      cursor: pointer;
      svg {
        display: none;
      }
    }
  }
}
.assessmentFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  min-height: 50px;
}
.assessmentFooterButton {
  display: flex;
  justify-content: flex-end;
  width: 160px;
  margin-left: auto;
}
.sendReport {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 24px;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
