.assessment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #2F353C;
    margin-bottom: 16px;
  }
}