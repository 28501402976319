@import "src/assets/css/mixins";
.profilePromo {
  padding: 0 32px 32px;
  @include mobile {
    margin-top: 16px;
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(61, 83, 116, 0.06);
    border-radius: 16px;
  }
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #2F353C;
    text-align: left;
    margin-bottom: 16px;
    @include mobile {
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;
      text-align: center;
      margin-bottom: 12px;
    }
  }
  & > [class="link-button"] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0 16px;
    cursor: pointer;
    p {
      display: flex;
      flex-direction: column;
      @include mobile {
        text-align: left;
      }
    }
    strong {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: #FFFFFF;
      opacity: 0.8;
    }
  }
}