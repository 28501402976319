@import "src/assets/css/variables";

.progressLine {
  position: relative;
  display: block;
  width: 100%;
  height: 8px;
  margin-right: 3px;
  border-radius: 4px;
  //overflow: hidden;
  margin-bottom: 32px;
  &::-webkit-progress-bar {
    background: $primary-white;
    border-radius: 4px;
  }
  &::-webkit-progress-value {
    background: #15C73C;
    border-radius: 4px 0 0 4px;
  }
  &[value="100"]::-webkit-progress-value {
    border-radius: 4px;
  }
}