@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.userProfile{
  background: #F9F9FA;

  .profileWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 32px 108px 32px;
    @include tablet {
      padding: 32px 24px;
      flex-direction: column;
    }
    @include mobile {
      padding: 16px;
    }
  }
  .profilePage {
    background: #F9F9FA;
    @include mobile {
      padding: 0 16px 16px;
      background: rgb(255,255,255);
      background: linear-gradient(
                      0deg, #FCFCFC 0%, #FCFCFC 59.99999999999%, #002846 60%);
    }
  }
}


