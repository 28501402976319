.testWrapper {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  text-align: left;
}
.testContent {
  width: 100%;
  max-width: 734px;
  min-height: 500px;
  padding: 32px 40px;
  background: #FCFCFC;
  border-radius: 16px;
  @media screen and (max-width: 640px) {
    padding: 16px;
  }
  & > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #2F353B;
    margin-bottom: 8px;
  }
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2F353C;
  }
  [class*="button_primary"] {
    width: 100%;
    max-width: 464px;
    margin: 0 auto;
  }
}
.testContentTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #2F353B;
  margin-bottom: 10px;
}
.testContentInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #2F353C;
}
.testSection {
  display: block;
  padding: 32px 0 0;
  & > h3 {
    display: flex;
    align-items: center;
    height: 28px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #002846;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 28px;
      padding: 0 8px;
      height: 28px;
      margin-left: 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #2F353C;
      background: #FFFFFF;
      border: 1px solid #0185F3;
      border-radius: 4px;
    }
  }
}
.imgTestSectionList {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 32px 0 20px;
  & > li {
    display: flex;
    position: relative;
    width: 100%;
    margin-bottom: 12px;
    input {
      display: none;
      &:checked + label {
        background: #EAF9FE;
        border: 2px solid #0185F3;
        box-shadow: 0px 1px 5px rgba(61, 83, 116, 0.08);
        border-radius: 10px;
        svg {
          display: block;
          position: absolute;
          top: 50%;
          right: 16px;
          width: 24px;
          height: 24px;
          transform: translateY(-50%);
        }
      }
    }
    label {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 0 16px;
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      box-shadow: 0px 1px 5px rgba(61, 83, 116, 0.08);
      border-radius: 10px;
      cursor: pointer;
      svg {
        display: none;
      }
    }
  }
}
.optionImage {
  max-width: calc(50% - 6px) !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 166px;
  & > label {
    height: 166px!important;
    padding: 0!important;
    overflow: hidden;
    max-width: 100%;
    justify-content: center;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      object-fit: cover;
    }
  }
  input {
    display: none;
    &:checked + label {
      position: relative;
      border: 2px solid #0185F3;
      border-radius: 10px;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #0185F3;
        opacity: 0.5;
      }
    }
  }
}
.testComplete {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #2F353B;
    margin-bottom: 16px;
  }
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2F353C;
    margin-bottom: 32px;
  }
  & > a {
    margin-bottom: 32px;
  }
  & > span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #729BB4;
  }
}
.testCompleteImage {
  width: 215px;
  height: 216px;
  margin-bottom: 24px;
  background: url("../../../assets/images/congratulations.png") 0 0 no-repeat;
  background-size: cover;
}