@import "src/assets/css/variables";
@import "src/assets/css/mixins";

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 24px;
  & + & {
    margin-top: 8px;
  }
  & + button {
    margin: 24px 0 0 0;
  }
}
.inputWrapperError {
  position: relative;
  input {
    background: $input-bg-color;
    border: 1px solid $input-border-color-error;
    border-radius: 10px;
  }
  p {
    position: absolute;
    bottom: 0;
    left: 20px;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: $input-text-color-error;
  }
}
.inputLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $input-text-color;
  margin: 0 0 8px;
}
.inputField {
  @include flexVC;
  width: 100%;
  height: 40px;
  padding: 0 36px 0 20px;
  background: $primary-white;
  border: 1px solid $input-border-color;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $input-text-color;
  outline: none;
  text-overflow: ellipsis;
}
.inputPassword {
  position: relative;
  width: 100%;
  height: 40px;
  input[type="password"] + button {
    background: url("../../../assets/images/password-show.png");
    background-size: cover;
  }
  input[type="text"] + button {
    background: url("../../../assets/images/password-hide.png");
    background-size: cover;
  }
}
.inputPasswordIcon {
  position: absolute;
  right: 14px;
  bottom: 50%;
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  transform: translateY(50%);
  cursor: pointer;
}