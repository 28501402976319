@import "src/assets/css/mixins";
.profileHeader {
  display: flex;
  align-items: center;
  position: relative;
  height: 156px;
  padding: 32px;
  background: #002846;
  border-radius: 16px 16px 0px 0px;
  @include mobile {
    justify-content: space-between;
    padding: 0;
    height: 164px;
    border-radius: 0;
  }
  & > [class*="avatarImage"] {
    width: 100%;
    max-width: 96px;
    height: 96px;
    margin-right: 29px;
    flex: 1 0 96px;
    @include mobile {
      max-width: 120px;
      height: 120px;
      flex: 1 0 120px;
      margin-right: 12px;
    }
  }
}
.profileHeaderInfo {
  @include mobile {
    width: calc(100% - 132px);
  }
  h3 {
    display: flex;
    align-items: center;
    height: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    color: #FFFFFF;
    margin-bottom: 8px;
    @include mobile {
      height: 24px;
      font-size: 17px;
      line-height: 24px;
    }
    span {
      max-width: 400px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.profileHeaderInfoStat {
  display: flex;
  align-items: center;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
  & > svg{
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
  span {
    color: #01AFEA;
    margin-left: 4px;
  }
}
.profileEditButton {
  position: absolute;
  right: 32px;
  bottom: 24px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #D0DFE8;
  text-decoration: none;
  padding: 0 0 0 31px;
  cursor: pointer;
  &:after{
    content: "";
    background: url("../../assets/images/icon-edit.png") 0 0 no-repeat;
    width: 24px;
    height: 24px;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  @include mobile {
    top: 0;
    right: 0;
    bottom: initial;
    font-size: 14px;
    &:after{
      width: 20px;
      height: 20px;
    }
  }
}