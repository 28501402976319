.leaderBoardUserInfo{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  img{
      max-width: 250px;
    }
  p{
    text-align: center;
  }
}
