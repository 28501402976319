@import "src/assets/css/variables";

.checkboxLabel {
  position: relative;
  display: flex;
  width: 51px;
  height: 31px;
  & > input[type="checkbox"] {
    display: none;
  }
}
.checkboxInput:checked {
  & + .checkbox {
    justify-content: flex-end;
    background: #34C759;
    & > li {
      border: none;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    }
  }
}
.checkbox {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 51px;
  height: 31px;
  padding: 2px;
  background: rgba(120, 120, 128, 0.16);
  border-radius: 27px;
  cursor: pointer;
  & > li {
    width: 27px;
    height: 27px;
    background: $primary-white;
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 100%;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
  }
}