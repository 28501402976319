@import "src/assets/css/mixins";
.studyWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 32px 108px 32px;
  @include tablet {
    padding: 32px 24px;
    flex-direction: column;
  }
  @include mobile {
    padding: 16px;
  }
  .studyComponent{
    align-self: flex-start;
    width: 100%;
    max-width: 760px;
    padding: 32px 56px;
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0 2px 12px rgba(61, 83, 116, 0.06);
    border-radius: 16px;
    @include tablet {
      max-width: 100%;
      padding: 16px;
    }
    @include mobile {
      padding: 16px;
    }
    & > h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      color: #2F353C;
      margin-bottom: 16px;
    }
    & > p {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #2F353C;
      margin-bottom: 32px;
    }
  }
}
.lessonContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  position: relative;
  & > section {
    width: 100%;
    & + section {
      margin-top: 16px;
      position: relative;
    }
  }
  & > [class*="button_primary"] {
    width: 100%;
    max-width: 480px;
    margin-top: 32px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2F353C;
  }
  & > [class*="image"] {
    display: flex;
    justify-content: center;
    width: 100%;
    div {
      width: 340px;
      height: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  & > [class*="button"] a {
    display: block;
    text-align: center;
    text-transform: lowercase;
    line-height: 52px;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}