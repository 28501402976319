@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.main {
  width: 100%;
  margin: 1.75rem auto;
  max-width: 528px;
  @include flexVC;
  outline: none;
  pointer-events: none;
  padding: 0 16px;
  &[class*="SelectCourse"] {
    width: 100%;
    max-width: 678px;
  }
  &[class*="statisticsInfo"] {
    width: 100%;
    max-width: 734px;
    padding: 16px;
    @include mobile {
      height: 100%;
    }
    .wrapper {
      height: 100%;
      overflow: auto;
    }
  }
  &[class*="pointsInfo"] {
    width: 100%;
    max-width: 760px;
    padding: 16px;
  }
  &[class*="leaderbordInfo"] {
    width: 100%;
    padding: 16px;
    height: 100%;
    @include mobile {
      padding-bottom: 115px;
      padding-top: 25px;
      .wrapper {
        height: 100%;
        overflow: auto;
      }
    }
  }
}
.overlay{
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(37, 32, 61, 0.6);
  @include flexVHC;
}
.wrapper{
  width: 100%;
  background: #FFF;
  border-radius: 1rem;
  padding: 2rem;
  pointer-events: all;

  .modalHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #2F353B;
    }
    button {
      @include flexVHC;
      width: 24px;
      height: 24px;
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}
.profileCoursesModal {
  padding-top: 24px;
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $text-color;
  }
}
.profileCoursesModalButtons {
  display: flex;
  justify-content: space-between;
  padding: 32px 0 0;
  & > [class*="button"] {
    justify-content: center;
  }
}