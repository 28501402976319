@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.onboardingWrapper {
  @include flexVHC;
  width: 100%;
  height: calc(100vh - 64px);
  padding: 16px;
  background: $onboard-wrapp-bg;
  @include mobile {
    height: auto;
    min-height: 100%;
  }
}
.onboardingFlow {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 734px;
  height: 570px;
  padding: 40px;
  background: $onboard-bg;
  border-radius: $onboard-radius;
  @include mobile {
    width: 100%;
    padding: 16px;
  }
.stepContainer {
  padding-right: 45px;
  @include mobile {
    display: none;
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-left: -1.5px;
    margin-right: -1.5px;
    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding-left: 3px;
      padding-right: 3px;
      > div {
        width: 100%;
        border-radius: 20px;
        background: linear-gradient(to left,#D0DFE8 50%,#0185F3 50%) right;
        background-size:200%;
        transition: 0.25s ease-out;
        height: 12px;
        &.active {
          background-position: left;
        }
      }
    }
  }
}

}
.onboardingClose {
  position: absolute;
  top: 33px;
  right: 40px;
  width: 24px;
  height: 24px;
  background: url("../../../../assets/images/close.png") 0 0 no-repeat;
  background-size: cover;
  z-index: 1;
  cursor: pointer;
  @include mobile {
    top: 11px;
    right: 16px;
  }
}
.onboardingFlow .slick-arrow  {
  display: flex;
  width: 100px;
  height: 100px;
}
.onboardingCarouselArrows {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 52px;
  @include mobile {
    flex: 0 0 auto;
  }
  .btnClass{
    margin: 0 95px;
  }
}