@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.langToLearnWrapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 948px;
  margin: 0 auto;
  padding: 140px 0 40px 0;
  @include mobile {
    padding: 16px 16px 32px;
  }
  form {
    width: 100%;
  }
}

.langToLearnHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #2F353B;
  margin-bottom: 40px;
  @include mobile {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 16px;
  }
}

.langToLearn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 168px;
  margin-bottom: 56px;
  @include mobile {
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 24px;
  }
}

.langToLearnItem {
  @include flexVHC;
  width: 164px;
  height: 168px;
  @include mobile {
    width: 100%;
    height: auto;
  }
  & + [class*="langToLearnItem"] {
    @include mobile {
      margin-top: 16px;
    }
  }

  input[type="radio"] {
    display: none;

    &:checked + label {
      background: #EAF9FE;
      border: 1px solid $primary-blue;
      box-shadow: 0px 1px 5px rgba(61, 83, 116, 0.08);

      &::after {
        content: '';
        position: absolute;
        right: 16px;
        bottom: 16px;
        width: 16px;
        height: 11px;
        background: url("../../../assets/images/lang-checked.png") 0 no-repeat;
        background-size: cover;
        @include mobile {
          bottom: initial;
        }
      }
    }
  }

  label {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 164px;
    height: 168px;
    padding: 30px;
    background: $primary-white;
    border: 1px solid transparent;
    box-shadow: 0px 1px 5px rgba(61, 83, 116, 0.08);
    border-radius: 10px;
    cursor: pointer;
    @include mobile {
      flex-direction: row;
      width: 100%;
      height: 64px;
      padding: 16px;
      & > [class*="icon"] {
        height: 40px;
        flex-direction: row;
      }
      [class*="flagIcon"] {
        width: 44px;
        height: 40px;
        margin-right: 4px;
        & + span {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}